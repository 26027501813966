// import styled, { css, device } from "styled-components";
import styled, { css, device } from "@styled";

export const SectionWrap = styled.div`
    padding-block: 30px;
     ${(props) =>
        props.layout === 1 &&
        css`
        padding:  30px 0 60px 0;
        ${device.large} {
            padding: 51px 0 85px 0;
            }
        `}
`;
